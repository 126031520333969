import revive_payload_client_Ps7OQtfxLc from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_9r45vmbOCP from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_p5A3829X8f from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_YEbks9looI from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_D1jNrXLYjy from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_rvPSqxbVbQ from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ZwNLHFroVi from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_eY0gwmtj7p from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_typescript@5.6.3_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_8cO9Pik1MX from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_U2VC1uZNCW from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt-bugsnag@7.4.0-beta.0_vue-router@4.4.5/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import primevue_plugin_egKpok8Auk from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/.nuxt/primevue-plugin.mjs";
import plugin_client_mLfkanTrf4 from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt-primevue@0.2.0_vue@3.5.12/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import _00_logger_client_lOQlgMMdSS from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/plugins/00.logger.client.ts";
import _01_auth_client_UOBMAirzzr from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/plugins/01.auth.client.ts";
import _02_api_client_XzPc4Tu48d from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/plugins/02.api.client.ts";
import _03_primetoast_client_LJtedLVtzk from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/plugins/03.primetoast.client.ts";
import _04_rbac_client_g0YdBjmGaR from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/plugins/04.rbac.client.ts";
import _05_amplifyhub_client_yr05Dd4iLN from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/plugins/05.amplifyhub.client.ts";
import _06_vue_masonry_wall_3gxUEZUPQL from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/plugins/06.vue-masonry-wall.ts";
import _07_websocket_client_I4vnkedcMC from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/plugins/07.websocket.client.ts";
import _99_sharedFunctions_client_GcMZueS7iU from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/plugins/99.sharedFunctions.client.ts";
export default [
  revive_payload_client_Ps7OQtfxLc,
  unhead_9r45vmbOCP,
  router_p5A3829X8f,
  payload_client_YEbks9looI,
  navigation_repaint_client_D1jNrXLYjy,
  check_outdated_build_client_rvPSqxbVbQ,
  chunk_reload_client_ZwNLHFroVi,
  plugin_vue3_eY0gwmtj7p,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8cO9Pik1MX,
  plugin_U2VC1uZNCW,
  primevue_plugin_egKpok8Auk,
  plugin_client_mLfkanTrf4,
  _00_logger_client_lOQlgMMdSS,
  _01_auth_client_UOBMAirzzr,
  _02_api_client_XzPc4Tu48d,
  _03_primetoast_client_LJtedLVtzk,
  _04_rbac_client_g0YdBjmGaR,
  _05_amplifyhub_client_yr05Dd4iLN,
  _06_vue_masonry_wall_3gxUEZUPQL,
  _07_websocket_client_I4vnkedcMC,
  _99_sharedFunctions_client_GcMZueS7iU
]