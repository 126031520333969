import { CognitoUser } from 'amazon-cognito-identity-js'
import type {
  UserResponse,
  UserPreferenceResponse,
  UserPreferenceUpdateRequest,
} from '../models/apiCore'
import type { rolesSystem } from '@/models/rbac'
import { useNotificationStore } from '~/stores/pushNotifications'

export interface AmplifyAttributes {
  email: string
  email_verified: boolean
  family_name: string
  given_name: string
  phone_number: string
  phone_number_verified: boolean
  sub: string
}
export interface Payload {
  aud: string
  auth_time: number
  'cognito:username': string
  companies: string
  email: string
  email_verified: boolean
  event_id: string
  exp: number
  family_name: string
  given_name: string
  iat: number
  iss: string
  jti: string
  origin_jti: string
  phone_number: string
  system_role: string
  token_use: string
  userId: string
}
export interface AmplifyToken {
  jwtToken: string
  payload: Payload
}

export const useUserStore = defineStore('user', () => {
  const returnURL: Ref<string> = ref('')
  const amplifyAttributes: Ref<AmplifyAttributes | null> = ref(null)
  const amplifyToken: Ref<AmplifyToken | null> = ref(null)
  const userDetails: Ref<UserResponse | null> = ref(null)
  const userPreferences: Ref<UserPreferenceResponse | null> = ref(null)
  const currentAuthenticatedUser: Ref<CognitoUser | null> = ref(null)
  const MFAUserData: Ref<CognitoUser | null> = ref(null)

  const userId = computed(() => {
    return userDetails?.value?.Id
  })
  const userEmail = computed(() => {
    return userDetails?.value?.Email
  })
  const initials = computed(() => {
    return `${userDetails?.value?.FirstName?.charAt(
      0,
    )}${userDetails?.value?.LastName?.charAt(0)}`
  })
  const getTokenClaims = computed(() => {
    if (amplifyToken?.value?.payload?.companies) {
      return JSON.parse(amplifyToken.value.payload.companies)
    }
    return null
  })
  const getSystemRole = computed(() => {
    if (amplifyToken?.value?.payload?.system_role) {
      return amplifyToken.value?.payload.system_role as
        | rolesSystem
        | 'client_user'
    }

    return 'client_user'
  })

  /**
   * Sets the return url
   * @param value
   */
  const setReturnURL = (value: string) => {
    returnURL.value = value
  }

  /**
   * Clears the return url
   */
  const clearReturnURL = () => {
    returnURL.value = ''
  }

  /**
   * Set's the user's amplify
   * @param value
   */
  // skipcq: JS-0323
  const setUserAmplify = (value: any) => {
    amplifyAttributes.value = value?.attributes
    amplifyToken.value = value?.signInUserSession?.idToken

    currentAuthenticatedUser.value = value
  }

  /**
   * Clears the user's amplify
   */
  const clearUserAmplify = () => {
    amplifyAttributes.value = null
    amplifyToken.value = null
  }
  const setUserPreferences = async () => {
    const { $api } = useNuxtApp()
    const prefs = await $api.core.api.getUserPreferences(
      userDetails?.value?.Id ?? 0,
    )
    userPreferences.value = prefs.data
  }
  const setUserDetails = (value: UserResponse) => {
    userDetails.value = value
  }
  const clearUserDetails = () => {
    userDetails.value = null
  }
  const refreshUserDetails = async () => {
    const { $api, $addToastError } = useNuxtApp()
    const response = await $api.core.api.getCurrentUser()
    if (response.status === 200) {
      setUserDetails(response.data)
      setUserPreferences()
    } else {
      $addToastError('Error', 'Error fetching user details')
    }
  }
  const purge = () => {
    clearReturnURL()
    clearUserAmplify()
    clearUserDetails()
  }
  const ensureLogout = async () => {
    const route = useRouter()
    const pushStore = useNotificationStore()
    const { $log, $auth, $WSDisconnect } = useNuxtApp()
    $log.debug('ensure logout')
    pushStore.setAutoReconnect(false)
    $WSDisconnect()
    purge()
    await $auth.amplify.signOut()
    $log.debug(`Route ${route.currentRoute.value.path}`)
    await navigateTo('/login')
    $log.debug('ensure logout complete')
  }

  const updatePreferences = (prefs: UserPreferenceResponse) => {
    const { $log, $api } = useNuxtApp()
    const request: UserPreferenceUpdateRequest = {
      ActiveTwoFactorInd: prefs.ActiveTwoFactorInd,
      NotifySendApprovalRemindersInd: prefs.NotifySendApprovalRemindersInd,
      UserId: userDetails.value?.Id,
      UserPreferenceId: prefs.UserPreferenceId,
    }
    $log.debug(request)
    $api.core.api.setUserPreferences(request)
  }

  return {
    returnURL,
    amplifyAttributes,
    amplifyToken,
    currentAuthenticatedUser,
    userDetails,
    userPreferences,
    userId,
    userEmail,
    initials,
    getTokenClaims,
    getSystemRole,
    MFAUserData,
    setReturnURL,
    clearReturnURL,
    setUserAmplify,
    clearUserAmplify,
    setUserDetails,
    updatePreferences,
    clearUserDetails,
    refreshUserDetails,
    purge,
    ensureLogout,
  }
})
