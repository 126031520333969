import { usePassThrough } from 'primevue/passthrough'
import type { PaginatorContext } from 'primevue/paginator'
import type { DropdownContext, DropdownProps } from 'primevue/dropdown'

const paginatorPageButton = ({ context }: { context: PaginatorContext }) => {
  return {
    class: [
      'btn btn-sm btn-circle mx-1',
      {
        'btn-primary': context.active === true,
        'btn-ghost': context.active === false,
      },
    ],
  }
}
const dropdown = ({
  props,
  _context,
}: {
  props: DropdownProps
  context: DropdownContext
}) => {
  return {
    root: () => ({
      class: [
        // Display and Position
        'inline-flex',
        'relative',

        // Shape
        'w-full md:w-56',
        'rounded-md',
        'shadow-sm',

        // Color and Background
        'bg-white',

        // Misc
        'cursor-default',
        'select-none',
        { 'opacity-60': props.disabled, 'pointer-events-none': props.disabled },
      ],
    }),
    input: () => ({
      class: [
        // Font
        'font-sans',
        'sm:text-sm',
        '!leading-loose',

        // Display
        'block',
        'flex-auto',

        // Color and Background
        'bg-transparent',
        'border-0',
        'text-surface-800',

        // Sizing and Spacing
        'w-[1%]',
        'py-1.5 px-3',
        { 'pr-7': props.showClear },

        // Shape
        'rounded-none',

        // Transitions
        'transition',
        'duration-200',

        // States
        'focus:outline-none focus:shadow-none',

        // Misc
        'relative',
        'cursor-pointer',
        'overflow-hidden overflow-ellipsis',
        'whitespace-nowrap',
        'appearance-none',
      ],
    }),
    trigger: {
      class: [
        // Font
        'sm:text-sm',

        // Flexbox
        'flex items-center justify-center',
        'shrink-0',

        // Color and Background
        'bg-transparent',
        'text-surface-500',

        // Size
        'w-12',

        // Shape
        'rounded-tr-md',
        'rounded-br-md',
      ],
    },
    panel: {
      class: [
        // Position
        'absolute top-0 left-0',
        'mt-2',

        // Shape
        'border-0',
        'rounded-md',
        'shadow-md',

        // Color
        'bg-surface-0',
        'text-surface-800',
      ],
    },
    wrapper: {
      class: [
        // Sizing
        'max-h-[15rem]',

        // Misc
        'overflow-auto',
      ],
    },
    list: {
      class: 'py-1 list-none bg-white rounded-sm m-0',
    },
    item: () => ({
      class: [
        // Font
        'sm:text-sm',
        'leading-none',

        // Position
        'relative',

        // Shape
        'border-0',
        'rounded-none',

        // Spacing
        'm-0',
        'py-2 px-4',

        // Color
        'text-black',

        // States
        'hover:bg-primary-500 hover:text-primary',

        // Misc
        'cursor-pointer',
        'overflow-hidden',
        'whitespace-nowrap',
      ],
    }),
    itemgroup: {
      class: [
        // Font
        'font-bold',
        'sm:text-sm',

        // Spacing
        'm-0',
        'py-2 px-4',

        // Color
        'text-surface-800',
        'bg-surface-0',

        // Misc
        'cursor-auto',
      ],
    },
    emptymessage: {
      class: [
        // Font
        'leading-none',
        'sm:text-sm',

        // Spacing
        'py-2 px-4',

        // Color
        'text-surface-800',
        'bg-transparent',
      ],
    },
    header: {
      class: [
        // Spacing
        'p-0',
        'm-0',

        // Shape
        'rounded-tl-md',
        'rounded-tr-md',

        // Color
        'text-surface-700',
        'bg-surface-100',
      ],
    },
    filtercontainer: {
      class: 'relative rounded-t bg-base-200 p-2',
    },
    filterinput: {
      class: [
        // Font
        'font-sans',
        'leading-none',
        'sm:text-sm',

        // Sizing
        'py-1.5 px-3',
        'pr-7',
        '-mr-7',
        'w-full',

        // Color
        'text-neutral',
        'bg-white',

        // Shape
        'border-0',
        'rounded',
        'appearance-none',

        // Misc
        'appearance-none',
      ],
    },
    filtericon: {
      class: ['absolute', 'top-1/2', '-mt-2', 'right-3'],
    },
    clearicon: {
      class: [
        // Color
        'text-surface-500',

        // Position
        'absolute',
        'top-1/2',
        'right-12',

        // Spacing
        '-mt-2',
      ],
    },
    transition: {
      enterFromClass: 'opacity-0 scale-y-[0.8]',
      enterActiveClass:
        'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
      leaveActiveClass: 'transition-opacity duration-100 ease-linear',
      leaveToClass: 'opacity-0',
    },
  }
}

const PrimePassthrough = usePassThrough(
  {
    datatable: {
      wrapper: {
        class: ['pb-20'],
      },
      table: {
        class: ['table table-zebra'],
      },
      rowexpansion: {
        class: 'bg-surface-0 text-surface-600',
      },
      rowgroupheader: {
        class: [
          'sticky z-20',
          'bg-surface-0 text-surface-600 dark:text-white/70',
          'dark:bg-surface-800',
        ],
      },
      rowgrouptoggler: {
        class: [
          'relative',
          'inline-flex items-center justify-center',
          'text-left',
          'm-0 p-0',
          'w-8 h-8',
          'border-0 rounded-full',
          'text-surface-500 dark:text-white/70',
          'bg-transparent',
          'focus-visible:outline-none focus-visible:outline-offset-0',
          'focus-visible:ring focus-visible:ring-primary-400/50 dark:focus-visible:ring-primary-300/50',
          'transition duration-200',
          'overflow-hidden',
          'cursor-pointer select-none',
        ],
      },
      rowgrouptogglericon: {
        class: 'inline-block w-4 h-4',
      },
    },

    autocomplete: {
      root: ({ props }) => ({
        class: [
          'relative',
          // Flex
          'inline-flex',
          // Size
          { 'w-full': props.multiple },
          // Color
          'text-surface-900 dark:text-surface-0',
          // States
          {
            'bg-surface-200 dark:bg-surface-700 select-none pointer-events-none cursor-default':
              props.disabled,
          },
        ],
      }),
      container: ({ props, state }) => ({
        class: [
          // Font
          'leading-none',
          // Flex
          'flex items-center flex-wrap',
          'gap-2',
          // Spacing
          'm-0 list-none',
          'p-1',
          // Size
          'w-full',
          // Shape
          'appearance-none rounded-md',
          // Color
          'text-surface-700 dark:text-white/80',
          'placeholder:text-surface-400 dark:placeholder:text-surface-500',
          { 'bg-surface-0 dark:bg-surface-950': !props.disabled },
          'border',
          { 'border-surface-300 dark:border-surface-700': !props.invalid },
          // Invalid State
          'invalid:focus:ring-red-200',
          'invalid:hover:border-red-500',
          { 'border-red-500 dark:border-red-400': props.invalid },
          // States
          {
            'hover:border-surface-400 dark:hover:border-surface-700':
              !props.invalid,
          },
          {
            'outline-none outline-offset-0 z-10 ring-1 ring-primary-500 dark:ring-primary-400':
              state.focused,
          },
          // Transition
          'transition duration-200 ease-in-out',
          // Misc
          'cursor-text overflow-hidden',
        ],
      }),
      inputtoken: {
        class: ['py-1 px-0 ml-2', 'inline-flex flex-auto'],
      },
      input: ({ props, parent }) => {
        let _a
        return {
          class: [
            'input w-full h-10 text-black bg-base-200',
            // Font
            'text-base leading-none',
            // Shape
            'appearance-none rounded-md',
            { 'rounded-tr-none rounded-br-none': props.dropdown },
            { 'outline-none shadow-none rounded-none': props.multiple },
            // Size
            { 'w-full': props.multiple },
            // Spacing
            'm-0',
            { 'py-2 px-3': !props.multiple, 'p-0': props.multiple },
            // Colors
            'text-surface-700 ',
            'border',
            {
              'bg-surface-0 ': !props.multiple,
              'border-surface-300': !props.multiple && !props.invalid,
              'border-0 bg-transparent': props.multiple,
            },
            // Invalid State
            { 'border-red-500': props.invalid },
            // States
            {
              'focus:outline focus:outline-2 focus:outline-slate-300 focus:ring-2 focus:ring-primary-500':
                !props.multiple,
            },
            // Filled State *for FloatLabel
            {
              filled:
                // skipcq
                ((_a = parent.instance) == null ? 0 : _a.$name) ===
                  'FloatLabel' && props.modelValue !== '',
            },
            // Transition
            'transition-colors duration-200',
          ],
        }
      },
      token: {
        class: [
          'inline-flex items-center',
          'py-1 px-3 m-0',
          'rounded',
          'bg-surface-100 dark:bg-surface-700',
          'text-surface-700',
          'cursor-default',
        ],
      },
      removeTokenIcon: {
        class: ['ml-[0.375rem]', 'w-4 h-4', 'cursor-pointer'],
      },
      dropdownbutton: {
        root: {
          class: [
            'relative',
            'items-center inline-flex justify-center text-center align-bottom',
            'rounded-r-md',
            'py-2 leading-none',
            'w-10',
            'text-primary-inverse',
            'bg-primary',
            'border border-primary',
            'focus:outline-none focus:outline-offset-0 focus:ring-1 ',
            'hover:bg-primary-hover hover:border-primary-hover',
            'focus:ring-primary-500 dark:focus:ring-primary-400',
          ],
        },
      },
      loadingicon: {
        class: [
          'text-surface-500 dark:text-surface-0/70',
          'absolute top-[50%] right-[0.5rem] -mt-2 animate-spin',
        ],
      },
      panel: {
        class: [
          'bg-white',
          'text-surface-700',
          'border border-surface-300 dark:border-surface-700',
          'rounded-md',
          'shadow-md',
          'overflow-auto',
        ],
      },
      list: {
        class: 'p-1 list-none m-0 bg-white',
      },
      item: ({ context }) => ({
        class: [
          'relative',
          // Font
          'leading-none',
          // Spacing
          'm-0 px-3 py-2',
          'first:mt-0 mt-[2px]',
          // Shape
          'border-0 rounded',
          // Colors
          {
            'text-black/80': !context.focused && !context.selected,
            'bg-white-600/60': context.focused && !context.selected,
            'text-primary-highlight-inverse': context.selected,
            'bg-primary-highlight': context.selected,
          },
          // States
          {
            'hover:bg-surface-100 dark:hover:bg-[rgba(255,255,255,0.03)]':
              !context.focused && !context.selected,
          },
          { 'hover:bg-primary-highlight-hover': context.selected },
          {
            'hover:text-surface-700 hover:bg-surface-100 ':
              context.focused && !context.selected,
          },
          // Transition
          'transition-shadow duration-200',
          // Misc
          'cursor-pointer overflow-hidden whitespace-nowrap',
        ],
      }),
      itemgroup: {
        class: [
          'font-semibold',
          'm-0 py-2 px-3',
          'text-surface-400 dark:text-surface-500',
          'cursor-auto',
        ],
      },
      emptymessage: {
        class: [
          'leading-none',
          'py-2 px-3',
          'text-surface-800',
          'bg-transparent',
        ],
      },
      transition: {
        enterFromClass: 'opacity-0 scale-y-[0.8]',
        enterActiveClass:
          'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
        leaveActiveClass: 'transition-opacity duration-100 ease-linear',
        leaveToClass: 'opacity-0',
      },
    },

    multiselect: {
      root: ({ props, state }) => ({
        class: [
          'select',
          // Display and Position
          'inline-flex',
          'relative',
          // Shape
          'rounded-md',
          'shadow-sm',
          // Color and Background
          'bg-base-200',
          'border-base-200',
          'ring-base-200',
          '!h-10 !min-h-10',
          // States
          {
            'ring-1 ring-inset': !state.focused,
            'ring-2 ring-inset ring-primary-500': state.focused,
          },
          {
            'ring-surface-300': !props.invalid && !state.focused,
          },
          // Invalid State
          { 'ring-red-500': props.invalid && !state.focused },
          // Misc
          'cursor-default',
          'select-none',
          {
            'opacity-60': props.disabled,
            'pointer-events-none': props.disabled,
          },
        ],
      }),
      labelContainer: {
        class: 'overflow-hidden flex flex-auto cursor-pointer',
      },
      label: ({ props }) => {
        let _b, _c
        return {
          class: [
            'block leading-5',
            'py-1 pr-3',
            // Color
            {
              'text-surface-800':
                (_b = props.modelValue) == null ? undefined : _b.length,
              'text-surface-400': !(
                (_c = props.modelValue) != null && _c.length
              ),
            },
            'leading-6',
            'placeholder:text-surface-400',
            // Transitions
            'transition duration-200',
            // Misc
            'overflow-hidden whitespace-nowrap cursor-pointer overflow-ellipsis',
          ],
        }
      },
      token: {
        class: [
          'inline-flex items-center',
          'py-0.5 px-3 mr-2',
          'rounded-[1.14rem]',
          'text-surface-700',
          'bg-surface-200',
        ],
      },
      removeTokenIcon: {
        class: [
          'rounded-md leading-6',
          'ml-2',
          'w-4 h-4',
          'transition duration-200 ease-in-out',
          'cursor-pointer',
        ],
      },
      trigger: {
        class: [
          'sm:text-sm',
          'flex items-center justify-center',
          'shrink-0',
          'bg-transparent',
          'text-transparent',
          'w-12',
          'rounded-tr-md',
          'rounded-br-md',
          'cursor-pointer',
        ],
      },
      panel: {
        class: [
          'absolute top-0 left-0',
          'mt-2',
          'border-1 border-slate-400',
          'rounded-md',
          'shadow-md',
          'bg-base-200 dark:bg-base-200',
          'text-surface-800',
          'ring-1 ring-slate-400',
        ],
      },
      header: {
        class: [
          'flex items-center justify-between',
          'py-2 px-4',
          'm-0',
          'border-b',
          'rounded-tl-md',
          'rounded-tr-md',
          'text-surface-700',
          'bg-surface-50',
          'border-surface-200',
        ],
      },
      headerCheckboxContainer: {
        class: [
          'relative',
          'inline-flex',
          'align-bottom',
          'w-4',
          'h-4',
          'mr-2',
          'cursor-default',
          'select-none',
        ],
      },
      headerCheckbox: {
        root: {
          class: [
            'relative',
            'inline-flex',
            'align-bottom',
            'w-4',
            'h-4',
            'mr-2',
            'cursor-default',
            'select-none',
          ],
        },
        box: ({ props, context }) => ({
          class: [
            // Alignment
            'flex',
            'items-center',
            'justify-center',
            // Size
            'w-4',
            'h-4',
            // Shape
            'rounded',
            'border',
            // Colors
            'text-surface-600',
            {
              'border-surface-300 bg-surface-0': !context.checked,
              'border-primary-500 bg-primary-500': context.checked,
            },
            {
              'ring-2 ring-primary-500': !props.disabled && context.focused,
              'cursor-default opacity-60': props.disabled,
            },
            // States
            {
              'peer-focus-visible:ring-2 peer-focus-visible:ring-primary-500':
                !props.disabled,
              'cursor-default opacity-60': props.disabled,
            },
            // Transitions
            'transition-colors',
            'duration-200',
          ],
        }),
        input: {
          class: [
            'peer',
            'w-full ',
            'h-full',
            'absolute',
            'top-0 left-0',
            'z-10',
            'p-0',
            'm-0',
            'rounded',
            'border',
            'opacity-0',
            'rounded-md',
            'outline-none',
            'border-2 border-surface-300',
            'appearance-none',
          ],
        },
        icon: {
          class: [
            'text-normal',
            'w-3',
            'h-3',
            'text-white',
            'transition-all',
            'duration-200',
          ],
        },
      },
      itemCheckbox: {
        root: {
          class: [
            'relative',
            'inline-flex',
            'align-bottom',
            'w-4',
            'h-4',
            'mr-2',
            'cursor-default',
            'select-none',
          ],
        },
        box: ({ props, context }) => ({
          class: [
            // Alignment
            'flex',
            'items-center',
            'justify-center',
            // Size
            'w-4',
            'h-4',
            // Shape
            'rounded',
            'border',
            // Colors
            'text-surface-600',
            {
              'border-surface-300 bg-surface-0 dark:border-surface-700':
                !context.checked,
              'border-primary-500 bg-primary-500 dark:border-primary-400':
                context.checked,
            },
            {
              'ring-2 ring-primary-500 dark:ring-primary-400':
                !props.disabled && context.focused,
              'cursor-default opacity-60': props.disabled,
            },
            // States
            {
              'peer-focus-visible:ring-2 peer-focus-visible:ring-primary-500':
                !props.disabled,
              'cursor-default opacity-60': props.disabled,
            },
            // Transitions
            'transition-colors',
            'duration-200',
          ],
        }),
        input: {
          class: [
            'peer',
            'w-full ',
            'h-full',
            'absolute',
            'top-0 left-0',
            'z-10',
            'p-0',
            'm-0',
            'rounded',
            'border',
            'opacity-0',
            'rounded-md',
            'outline-none',
            'border-2 border-surface-300',
            'appearance-none',
          ],
        },
        icon: {
          class: [
            'text-normal',
            'w-3',
            'h-3',
            'text-white',
            'transition-all',
            'duration-200',
          ],
        },
      },
      closeButton: {
        class: [
          'relative',
          'flex items-center justify-center',
          'mr-2',
          'last:mr-0',
          'w-6 h-6',
          'border-0',
          'rounded-full',
          'text-surface-500',
          'bg-transparent',
          'transition duration-200 ease-in-out',
          'hover:text-surface-700 ',
          'hover:bg-surface-100 ',
          'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-inset',
          'focus:ring-primary-500',
          'overflow-hidden',
        ],
      },
      closeButtonIcon: {
        class: ['inline-block', 'w-3', 'h-3'],
      },
      wrapper: {
        class: ['max-h-[15rem]', 'overflow-auto'],
      },
      list: {
        class: 'py-1 list-none m-0',
      },
      item: ({ context }) => ({
        class: [
          // Font
          'sm:text-sm',
          'leading-none',
          { 'font-normal': !context.selected, 'font-bold': context.selected },
          // Flexbox
          'flex items-center',
          // Position
          'relative',
          // Shape
          'border-0',
          'rounded-none',
          // Spacing
          'm-0',
          'py-2 px-4',
          // States
          'hover:bg-primary-500 hover:text-primary',
          // Misc
          'cursor-pointer',
          'overflow-hidden',
          'whitespace-nowrap',
        ],
      }),
      itemgroup: {
        class: [
          'font-bold',
          'sm:text-sm',
          'm-0',
          'py-2 px-4',
          'pl-4',
          'text-surface-800',
          'bg-surface-0',
          'cursor-auto',
        ],
      },
      filtercontainer: {
        class: 'relative w-full mr-2',
      },
      filterinput: {
        class: [
          'font-sans',
          'leading-none',
          'sm:text-sm',
          'py-1.5 px-3',
          'pr-7',
          '-mr-7',
          'w-full',
          'text-surface-700',
          'bg-base-200',
          'placeholder:text-surface-400',
          'ring-slate-400',
          'border-slate-400',
          'rounded-md',
          'appearance-none',
          'ring-1 focus:ring-inset focus:outline-none focus:outline-offset-0',
        ],
      },
      filtericon: {
        class: ['absolute', 'top-1/2 right-3', '-mt-2'],
      },
      clearicon: {
        class: ['text-surface-500', 'absolute', 'top-1/2', 'right-12', '-mt-2'],
      },
      emptymessage: {
        class: [
          'leading-none',
          'sm:text-sm',
          'py-2 px-4',
          'text-surface-800',
          'bg-transparent',
        ],
      },
      transition: {
        enterFromClass: 'opacity-0 scale-y-[0.8]',
        enterActiveClass:
          'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
        leaveActiveClass: 'transition-opacity duration-100 ease-linear',
        leaveToClass: 'opacity-0',
      },
    },
    calendar: {
      root: ({ props: e }) => ({
        class: [
          'inline-flex',
          'w-full',
          'relative',
          'shadow-sm',
          'rounded-md',
          {
            'opacity-40 select-none pointer-events-none cursor-default':
              e.disabled,
          },
        ],
      }),
      input: ({ props: e }) => ({
        class: [
          'font-sans w-full leading-none h-10 sm:text-sm',
          'text-surface-900 dark:text-surface-0',
          'placeholder:text-surface-400 dark:placeholder:text-surface-500',
          'bg-base-200 dark:bg-surface-900',
          'ring-1 ring-inset ring-surface-300 dark:ring-surface-700 ring-offset-0',
          'm-0 py-1.5 px-3',
          '-ml-[1px]',
          'appearance-none',
          { 'rounded-md': !e.showIcon || e.iconDisplay === 'input' },
          {
            'rounded-l-md  flex-1 pr-9 ':
              e.showIcon && e.iconDisplay !== 'input',
          },
          { 'rounded-md flex-1 pr-9': e.showIcon && e.iconDisplay === 'input' },
          'transition-colors',
          'duration-200',
          'outline-none focus:ring-primary-500 dark:focus:ring-primary-400',
        ],
      }),
      inputicon: {
        class: [
          'sm:text-sm',
          'absolute top-[50%] -mt-2',
          'text-surface-600 dark:text-surface-200',
          'right-[.75rem]',
        ],
      },
      dropdownbutton: {
        root: {
          class: [
            'relative text-sm',
            'items-center inline-flex text-center align-bottom',
            'rounded-r-md',
            'px-2.5 py-1.5 leading-none',
            'text-surface-600 dark:text-surface-100',
            'bg-surface-100 dark:bg-surface-800',
            'ring-1 ring-inset ring-surface-300 dark:ring-surface-700',
            'hover:bg-surface-200 dark:hover:bg-surface-700',
            'focus:outline-none focus:outline-offset-0 focus:ring-1',
            'focus:ring-primary-500 dark:focus:ring-primary-400',
          ],
        },
      },
      panel: ({ props: e }) => ({
        class: [
          { absolute: !e.inline, 'inline-block': e.inline },
          { 'w-auto p-2 ': !e.inline },
          { 'min-w-[80vw] w-auto p-2 ': e.touchUI },
          { 'p-2 min-w-full': e.inline },
          'rounded-lg',
          { 'shadow-md ring-1': !e.inline },
          'bg-gray-50 dark:bg-surface-800',
          'ring-surface-200 dark:ring-surface-700',
          { 'overflow-x-auto': e.inline },
        ],
      }),
      datepickerMask: {
        class: [
          'fixed top-0 left-0 w-full h-full',
          'flex items-center justify-center',
          'bg-black bg-opacity-90',
        ],
      },
      header: ({ props: e }) => ({
        class: [
          'font-semibold text-md',
          'flex items-center justify-between',
          'm-0',
          {
            'py-2 pl-2 pb-4': !(e.numberOfMonths > 1),
            'py-2 pb-4': e.numberOfMonths > 1,
          },
          'rounded-t-md',
          'text-surface-700 ',
          'bg-surface-0 dark:bg-surface-800',
        ],
      }),
      previousbutton: ({ props: e }) => ({
        class: [
          'relative',
          'inline-flex items-center justify-center',
          {
            ' order-2': !(e.numberOfMonths > 1),
            'order-1': e.numberOfMonths > 1,
          },
          'p-1.5 m-0',
          'text-surface-500 ',
          'border-0',
          'bg-transparent',
          'hover:text-surface-700 ',
          'cursor-pointer overflow-hidden',
        ],
      }),
      title: ({ props: e }) => ({
        class: [
          'leading-6',
          'my-0',
          'order-1',
          {
            'mr-auto': !(e.numberOfMonths > 1),
            ' mx-auto': e.numberOfMonths > 1,
          },
        ],
      }),
      monthTitle: {
        class: [
          'text-base leading-6',
          'font-semibold',
          'text-surface-700 ',
          'transition duration-200',
          'm-0 mr-2',
          'hover:text-primary-500 dark:hover:text-primary-400',
          'cursor-pointer',
        ],
      },
      yearTitle: {
        class: [
          'text-base leading-6',
          'font-semibold',
          'text-surface-700 ',
          'transition duration-200',
          'm-0',
          'hover:text-primary-500 dark:hover:text-primary-400',
          'cursor-pointer',
        ],
      },
      nextbutton: ({ props: e }) => ({
        class: [
          'relative',
          'inline-flex items-center justify-center order-3',
          {
            ' order-3': !(e.numberOfMonths > 1),
            'order-1': e.numberOfMonths > 1,
          },
          'p-1.5 m-0',
          'text-surface-500',
          'border-0',
          'bg-transparent',
          'hover:text-surface-700 ',
          'cursor-pointer overflow-hidden',
        ],
      }),
      table: {
        class: ['w-full', 'm-0 my-2'],
      },
      tableheadercell: {
        class: ['p-0 md:p-2'],
      },
      tablebodyrow: {
        class: [
          'border-b border-surface-200 dark:border-surface-700 last:border-b-0',
        ],
      },
      weekheader: {
        class: [
          'leading-6 text-sm font-normal',
          'text-surface-600',
          'opacity-40 cursor-default',
          'mb-2',
        ],
      },
      weeknumber: {
        class: ['text-surface-600  font-normal', 'opacity-40 cursor-default'],
      },
      weekday: {
        class: ['text-surface-500  font-normal'],
      },
      day: {
        class: ['p-0 md:p-2'],
      },
      weeklabelcontainer: ({ context: e }) => ({
        class: [
          'flex items-center justify-center',
          'mx-auto',
          'w-10 h-10',
          'rounded-full',
          'border-transparent border',
          {
            'text-surface-600  bg-transparent': !e.selected && !e.disabled,
            'text-primary-500  dark:text-primary-400':
              e.selected && !e.disabled,
          },
          'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50 dark:focus:ring-primary-300/50',
          { 'hover:bg-surface-100 dark:hover:bg-surface-800/80': !e.disabled },
          {
            'opacity-40 cursor-default': e.disabled,
            'cursor-pointer': !e.disabled,
          },
        ],
      }),
      daylabel: ({ context: e }) => ({
        class: [
          'flex items-center justify-center',
          'mx-auto',
          'w-8 h-8',
          'rounded-full',
          {
            'text-surface-0 bg-surface-900 dark:text-surface-900 dark:bg-surface-0':
              e.date.today && !e.selected && !e.disabled,
            'text-surface-600 ': !e.selected && !e.disabled && !e.date.today,
            'text-primary-500 dark:text-primary-400':
              e.selected && !e.disabled && !e.date.today,
            'text-primary-200 dark:text-primary-600 bg-surface-900 dark:bg-surface-0':
              e.selected && !e.disabled && e.date.today,
          },
          'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400',
          {
            'hover:bg-surface-100 dark:hover:bg-surface-600/80': !e.disabled,
            'hover:bg-surface-700 dark:hover:bg-surface-200':
              !e.disabled && e.date.today,
          },
          {
            'opacity-40 cursor-default': e.disabled,
            'cursor-pointer': !e.disabled,
          },
        ],
      }),
      monthpicker: {
        class: ['my-2'],
      },
      month: ({ context: e }) => ({
        class: [
          'inline-flex items-center justify-center',
          'w-1/3',
          'px-2.5 py-1.5',
          'mt-1',
          'text-md leading-none',
          'rounded-md',
          {
            'text-surface-600  bg-transparent': !e.selected && !e.disabled,
            'text-primary-500 dark:text-primary-400': e.selected && !e.disabled,
          },
          'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400',
          'hover:bg-surface-100 dark:hover:bg-surface-600/80',
          'cursor-pointer',
        ],
      }),
      yearpicker: {
        class: ['my-2'],
      },
      year: ({ context: e }) => ({
        class: [
          'inline-flex items-center justify-center',
          'w-1/3',
          'px-2.5 py-1.5',
          'mt-1',
          'text-md leading-none',
          'rounded-md',
          {
            'text-surface-600  bg-transparent': !e.selected && !e.disabled,
            'text-primary-500 dark:text-primary-400': e.selected && !e.disabled,
          },
          'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400',
          'hover:bg-surface-100 dark:hover:bg-surface-600/80',
          'cursor-pointer',
        ],
      }),
      timepicker: {
        class: ['flex', 'justify-center items-center', 'p-1.5'],
      },
      separatorcontainer: {
        class: ['flex', 'items-center', 'flex-col', 'px-2'],
      },
      separator: {
        class: ['text-xl'],
      },
      hourpicker: {
        class: ['flex', 'items-center', 'flex-col', 'px-2'],
      },
      minutepicker: {
        class: ['flex', 'items-center', 'flex-col', 'px-2'],
      },
      ampmpicker: {
        class: ['flex', 'items-center', 'flex-col', 'px-2'],
      },
      incrementbutton: {
        class: [
          'relative',
          'inline-flex items-center justify-center',
          'p-1.5 m-0',
          'text-surface-500 ',
          'border-0',
          'bg-transparent',
          'hover:text-surface-700 ',
          'cursor-pointer overflow-hidden',
        ],
      },
      decrementbutton: {
        class: [
          'relative',
          'inline-flex items-center justify-center',
          'p-1.5 m-0',
          'text-surface-500 ',
          'border-0',
          'bg-transparent',
          'hover:text-surface-700 ',
          'cursor-pointer overflow-hidden',
        ],
      },
      groupcontainer: {
        class: ['flex'],
      },
      group: {
        class: [
          'flex-1',
          'border-l',
          'border-surface-200 dark:border-surface-700',
          'pr-0.5',
          'pl-0.5',
          'pt-0',
          'pb-0',
          'first:pl-0',
          'first:border-l-0',
        ],
      },
      buttonbar: {
        class: [
          'flex justify-between items-center',
          'pt-2.5 pb-1.5 px-0',
          'border-t border-surface-200 dark:border-surface-700',
        ],
      },
      todaybutton: {
        root: {
          class: [
            'inline-flex items-center justify-center',
            'px-2.5 py-1.5 text-sm leading-none',
            'rounded-md',
            'bg-transparent border-transparent',
            'text-primary-500 dark:text-primary-400',
            'transition-colors duration-200 ease-in-out',
            'focus:outline-none focus:outline-offset-0 focus:ring-2 ring-inset',
            'focus:ring-primary-500 dark:focus:ring-primary-400',
            'hover:bg-primary-300/20',
            'cursor-pointer',
          ],
        },
      },
      clearbutton: {
        root: {
          class: [
            'inline-flex items-center justify-center',
            'px-2.5 py-1.5 text-sm leading-none',
            'rounded-md',
            'bg-transparent border-transparent',
            'text-primary-500 dark:text-primary-400',
            'transition-colors duration-200 ease-in-out',
            'focus:outline-none focus:outline-offset-0 focus:ring-2 ring-inset',
            'focus:ring-primary-500 dark:focus:ring-primary-400',
            'hover:bg-primary-300/20',
            'cursor-pointer',
          ],
        },
      },
      transition: {
        enterFromClass: 'opacity-0 scale-y-[0.8]',
        enterActiveClass:
          'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
        leaveActiveClass: 'transition-opacity duration-100 ease-linear',
        leaveToClass: 'opacity-0',
      },
    },
    paginator: {
      root: {
        class: ['flex items-center justify-center mt-5'],
      },
      firstPageButton: {
        class: ['btn btn-sm btn-ghost btn-circle mx-1'],
      },
      previousPageButton: {
        class: ['btn btn-sm btn-ghost btn-circle mx-1'],
      },
      nextPageButton: {
        class: ['btn btn-sm btn-ghost btn-circle mx-1'],
      },
      lastPageButton: {
        class: ['btn btn-sm btn-ghost btn-circle mx-1'],
      },
      pageButton: paginatorPageButton,
      current: {
        class: ['btn btn-sm btn-primary btn-circle mx-1'],
      },
      rowPerPageDropdown: {
        class: ['btn btn-primary ml-3'],
      },
    },
    column: {
      headerContent: {
        class: ['flex items-center'],
      },
      sort: {
        class: ['ml-3'],
      },
    },
    progressbar: {
      root: {
        class: ['bg-base-200 rounded-sm'],
      },
      value: {
        class: ['bg-primary text-white rounded-sm items-center justify-center'],
      },
    },
    toast: {
      props: {
        baseZIndex: 1000,
      },
      message: {
        class: ['w-full relative p-2'],
      },
      content: {
        class: ['flex items-center'],
      },
      icon: {
        class: ['w-5 h-5'],
      },
      text: {
        class: ['px-3'],
      },
      summary: {
        class: ['font-bold'],
      },
      buttonContainer: {
        class: ['self-start ml-5'],
      },
      closeButton: {
        class: ['btn btn-ghost btn-circle btn-xs hover:bg-white'],
      },
    },
    steps: {
      root: 'relative',
      menu: 'p-0 m-0 list-none flex',
      menuitem: {
        class: [
          'relative flex justify-center flex-1 overflow-hidden before:border-t before:border-neutral before:w-full before:absolute before:top-1/4 before:left-0 before:transform before:-translate-y-1/2',
        ],
      },
    },
    password: {
      root: ({ props }) => ({
        class: [
          'inline-flex relative',
          {
            'opacity-60 select-none pointer-events-none cursor-default':
              props.disabled,
          },
        ],
      }),
      panel: {
        class: [
          'p-3',
          'border-0',
          'shadow-md rounded-md',
          'bg-white',
          'text-surface-700',
        ],
      },
      meter: {
        class: [
          'overflow-hidden',
          'relative',
          'border-0',
          'h-2',
          'rounded-md',
          'mb-2',
          'bg-surface-100',
        ],
      },
      meterlabel: ({ instance }) => {
        let _a, _b, _c
        return {
          class: [
            // Size
            'h-full',
            // Colors
            {
              'bg-red-500':
                ((_a = instance === null ? undefined : instance.meter) === null
                  ? undefined
                  : _a.strength) === 'weak',
              'bg-orange-500':
                ((_b = instance === null ? undefined : instance.meter) === null
                  ? undefined
                  : _b.strength) === 'medium',
              'bg-green-500':
                ((_c = instance === null ? undefined : instance.meter) === null
                  ? undefined
                  : _c.strength) === 'strong',
            },
            // Transitions
            'transition-all duration-1000 ease-in-out',
          ],
        }
      },
      showicon: {
        class: ['absolute top-1/2 right-3 -mt-2', 'text-black'],
      },
      hideicon: {
        class: ['absolute top-1/2 right-3 -mt-2', 'text-black'],
      },
      input: {
        root: ({ props, context, parent }) => ({
          class: [
            // Font
            'font-sans leading-6',
            // Flex
            { 'flex-1 w-[1%]': parent.instance.$name === 'InputGroup' },
            // Spacing
            'm-0',
            {
              'py-3 px-4 text-lg sm:text-md': props.size === 'large',
              'py-1 px-2 sm:text-sm': props.size === 'small',
              'py-1.5 px-3 h-10 w-full sm:text-sm': props.size == null,
            },
            // Colors
            'text-black dark:text-surface-0',
            'placeholder:text-surface-400',
            'bg-surface-0 dark:bg-surface-900',
            'shadow-sm',
            {
              'ring-1 ring-inset ring-surface-300 ring-offset-0':
                parent.instance.$name !== 'InputGroup',
            },
            // Shape
            { 'rounded-md': parent.instance.$name !== 'InputGroup' },
            {
              'first:rounded-l-md rounded-none last:rounded-r-md':
                parent.instance.$name === 'InputGroup',
            },
            {
              'border-0 border-y border-l last:border-r border-surface-300':
                parent.instance.$name === 'InputGroup',
            },
            {
              'first:ml-0 ml-[-1px]':
                parent.instance.$name === 'InputGroup' && !props.showButtons,
            },
            'appearance-none',
            // Interactions
            {
              'outline-none focus:ring-primary-500': !context.disabled,
              'opacity-60 select-none pointer-events-none cursor-default':
                context.disabled,
            },
          ],
        }),
      },
      transition: {
        enterFromClass: 'opacity-0 scale-y-[0.8]',
        enterActiveClass:
          'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
        leaveActiveClass: 'transition-opacity duration-100 ease-linear',
        leaveToClass: 'opacity-0',
      },
    },
    fileupload: {
      input: {
        class: 'hidden',
      },
      buttonbar: {
        class: [],
      },
      chooseButton: {
        class: [],
      },
      chooseIcon: {
        class: ['hidden'],
      },
      chooseButtonLabel: {
        class: ['hidden'],
      },
      uploadbutton: {
        class: ['hidden'],
      },
      cancelbutton: {
        class: ['hidden'],
      },
      message: {
        root: ({ props }) => ({
          class: [
            'absolute',
            '-top-20',
            'my-4 mx-0',
            'rounded-md',
            'border-solid border-0 border-l-[6px]',
            'right-5 left-5',
            {
              'bg-blue-100': props.severity === 'info',
              'bg-green-100': props.severity === 'success',
              'bg-orange-100': props.severity === 'warn',
              'bg-red-100': props.severity === 'error',
            },
            {
              'border-blue-500': props.severity === 'info',
              'border-green-500': props.severity === 'success',
              'border-orange-500': props.severity === 'warn',
              'border-red-500': props.severity === 'error',
            },
            {
              'text-blue-700': props.severity === 'info',
              'text-green-700': props.severity === 'success',
              'text-orange-700': props.severity === 'warn',
              'text-red-700': props.severity === 'error',
            },
          ],
        }),
        wrapper: {
          class: ['flex items-center', 'py-5 px-7'],
        },
        icon: {
          class: ['w-6 h-6', 'text-lg leading-none mr-2 shrink-0'],
        },
        text: {
          class: ['text-base leading-none', 'font-medium'],
        },
        button: {
          class: [
            'flex items-center justify-center',
            'w-8 h-8',
            'ml-auto  relative',
            'rounded-full',
            'bg-transparent',
            'transition duration-200 ease-in-out',
            'hover:bg-surface-0/50 dark:hover:bg-surface-0/10',
            'overflow-hidden',
          ],
        },
        transition: {
          enterFromClass: 'opacity-0',
          enterActiveClass: 'transition-opacity duration-300',
          leaveFromClass: 'max-h-40',
          leaveActiveClass:
            'overflow-hidden transition-all duration-300 ease-in',
          leaveToClass: 'max-h-0 opacity-0 !m-0',
        },
      },
      content: {
        class: [
          'relative',
          'bg-surface-0',
          'text-surface-700',
          'px-8 pt-8 pb-12',
          'border',
          'border-surface-200',
          'rounded-b-lg',
        ],
      },
      file: {
        class: [
          'flex',
          'items-center',
          'flex-wrap',
          'p-4',
          'mb-2',
          'last:mb-0',
          'border',
          'border-surface-200',
          'gap-2',
          'rounded',
        ],
      },
      thumbnail: {
        class: 'shrink-0 min-w-12',
      },
      fileName: {
        class: 'mb-2',
      },
      fileSize: {
        class: 'mr-2',
      },
      uploadicon: {
        class: 'mr-2',
      },
      progressbar: {
        class: ['hidden'],
        root: {
          class: ['hidden'],
        },
        value: {
          class: ['hidden'],
        },
      },
    },
    selectbutton: {
      root: ({ props }) => ({
        class: [
          {
            'opacity-60 select-none pointer-events-none cursor-default bg-accent float-right':
              props.disabled,
          },
        ],
      }),
      button: ({ context, props }) => ({
        class: [
          'relative',
          // Font
          'leading-none',
          // Flex Alignment
          'inline-flex items-center align-bottom text-center',
          // Spacing
          'px-4 py-3',
          // Shape
          'border border-r-0',
          'first:rounded-l-md first:rounded-tr-none first:rounded-br-none',
          'last:border-r last:rounded-tl-none last:rounded-bl-none last:rounded-r-md',
          // Color
          {
            'bg-primary ': !context.active,
            'text-white ': !context.active,
            'border-surface-200': !context.active && !props.invalid,
            'bg-accent text-white pointer-events-none': context.active,
          },
          // Invalid State
          { 'border-red-500': props.invalid },
          // States
          'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50',
          {
            'hover:bg-surface-50': !context.active && !props.invalid,
            'hover:bg-primary-600': context.active,
          },
          {
            'opacity-60 select-none pointer-events-none cursor-default':
              context.disabled,
          },
          // Transition
          'transition duration-200',
          // Misc
          'cursor-pointer select-none overflow-hidden',
        ],
      }),
      label: {
        class: 'font-bold',
      },
    },
    contextmenu: {
      root: {
        class: [
          'min-w-[12rem]',
          'rounded-md',
          'shadow-md',
          'p-1.5',
          'bg-surface-0',
          'text-surface-700',
        ],
      },
      menu: {
        class: [
          'list-none',
          'm-0',
          'p-1',
          'outline-none',
          'bg-white',
          'rounded-md',
        ],
      },
      menuitem: {
        class: 'relative',
      },
      content: ({ context }) => ({
        class: [
          // Shape
          'rounded-md text-primary',
          //  Colors
          {
            'text-surface-500': !context.focused && !context.active,
            'text-surface-500 bg-surface-200':
              context.focused && !context.active,
            'text-surface-900 bg-surface-50': context.focused && context.active,
            'text-surface-900 bg-surface-50 ':
              !context.focused && context.active,
          },
          // Hover States
          {
            'hover:bg-base-200': !context.active,
            'hover:bg-base-200 ': context.active,
          },
          // Transitions
          'transition-shadow',
          'duration-200',
          // Disabled
          { 'opacity-60 pointer-events-none cursor-default': context.disabled },
        ],
      }),
      action: {
        class: [
          'relative',
          'font-normal',
          'flex',
          'items-center',
          'py-2',
          'px-3',
          'no-underline',
          'overflow-hidden',
          'cursor-pointer',
          'select-none',
        ],
      },
      icon: {
        class: ['mr-2', 'leading-6', 'text-sm'],
      },
      label: {
        class: ['leading-6', 'text-sm'],
      },
      submenu: ({ props }) => ({
        class: [
          // Size
          'w-full sm:w-48',
          // Spacing
          'p-1.5',
          'm-0',
          'list-none',
          // Shape
          'shadow-md',
          'rounded-md',
          // Position
          'static sm:absolute',
          'z-10',
          { 'sm:absolute sm:left-full sm:top-0': props.level > 1 },
          // Color
          'bg-surface-0',
        ],
      }),
      submenuicon: {
        class: ['ml-auto'],
      },
      separator: {
        class: 'border-t border-surface-200 my-1',
      },
      transition: {
        enterFromClass: 'opacity-0',
        enterActiveClass: 'transition-opacity duration-250',
      },
    },
    scrollpanel: {
      contentContainer: {
        class: [
          'h-full w-full',
          'z-[1]',
          'overflow-hidden',
          'relative float-left',
        ],
      },
      wrapper: {
        class: [
          'h-full w-full',
          'z-[1]',
          'overflow-hidden',
          'relative float-left',
        ],
      },
      content: {
        class: [
          'h-[calc(100%+18px)] w-[calc(100%+18px)] pr-[18px] pb-[18px] pl-0 pt-0',
          'overflow-scroll scrollbar-none',
          'box-border',
          'relative',
          '[&::-webkit-scrollbar]:hidden',
        ],
      },
      barX: {
        class: [
          'h-[9px] bottom-0',
          'bg-surface-50 dark:bg-surface-700 rounded',
          'cursor-pointer',
          'invisible z-20',
          'transition duration-[250ms] ease-linear',
          'relative',
        ],
      },
      barY: {
        class: [
          'w-[9px] top-0',
          'bg-secondary dark:bg-secondary rounded',
          'cursor-pointer',
          'z-20',
          'transition duration-[250ms] ease-linear',
          'relative',
        ],
      },
    },
    timeline: {
      root: ({ props }) => ({
        class: [
          'flex grow',
          {
            'flex-col': props.layout === 'vertical',
            'flex-row': props.layout === 'horizontal',
          },
        ],
      }),
      event: ({ props, context }) => ({
        class: [
          'flex relative min-h-[70px]',
          {
            'flex-row-reverse':
              props.align === 'right' ||
              (props.layout === 'vertical' &&
                props.align === 'alternate' &&
                context.index % 2 === 1),
            'flex-col [&:not(:last-child)]:flex-1':
              props.layout === 'horizontal',
            'flex-col-reverse ':
              props.align === 'bottom' ||
              (props.layout === 'horizontal' &&
                props.align === 'alternate' &&
                context.index % 2 === 1),
          },
        ],
      }),
      eventOpposite: ({ props, context }) => ({
        class: [
          'flex-1',
          {
            'px-4': props.layout === 'vertical',
            'py-4': props.layout === 'horizontal',
          },
          {
            'text-right':
              props.align === 'left' ||
              (props.layout === 'vertical' &&
                props.align === 'alternate' &&
                context.index % 2 === 0),
            'text-left':
              props.align === 'right' ||
              (props.layout === 'vertical' &&
                props.align === 'alternate' &&
                context.index % 2 === 1),
          },
        ],
      }),
      eventSeparator: ({ props }) => ({
        class: [
          'flex items-center flex-initial',
          {
            'flex-col': props.layout === 'vertical',
            'flex-row': props.layout === 'horizontal',
          },
        ],
      }),
      eventMarker: {
        class: [
          'relative',
          'inline-flex items-center justify-center',
          'w-[1.125rem] h-[1.125rem]',
          'rounded-full border-2 border-surface-200 bg-surface-0 dark:border-surface-700 dark:bg-surface-950',
          'before:rounded-full before:w-[0.375rem] before:h-[0.375rem] before:bg-primary',
          'after:absolute after:rounded-full after:w-full after:h-full after:shadow-sm',
        ],
      },
      eventConnector: ({ props }) => ({
        class: [
          'grow bg-surface-300 dark:bg-surface-700',
          {
            'w-[2px]': props.layout === 'vertical',
            'w-full h-[2px]': props.layout === 'horizontal',
          },
        ],
      }),
      eventContent: ({ props, context }) => ({
        class: [
          'flex-1',
          {
            'px-4': props.layout === 'vertical',
            'py-4': props.layout === 'horizontal',
          },
          {
            'text-left':
              props.align === 'left' ||
              (props.layout === 'vertical' &&
                props.align === 'alternate' &&
                context.index % 2 === 0),
            'text-right':
              props.align === 'right' ||
              (props.layout === 'vertical' &&
                props.align === 'alternate' &&
                context.index % 2 === 1),
          },
          {
            'min-h-0':
              props.layout === 'vertical' &&
              context.index === context.count - 1,
            'grow-0':
              props.layout === 'horizontal' &&
              context.index === context.count - 1,
          },
        ],
      }),
    },
    dropdown,
  },
  {},
  { mergeProps: false },
)

export default PrimePassthrough
