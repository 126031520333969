import Bugsnag from '@bugsnag/js'
import { defineNuxtPlugin } from 'nuxt/app'
import axios, { type AxiosError } from 'axios'
import { ApiCore, type ApiConfig } from '../models/apiCore'
import { useUserStore } from '@/stores/user'
interface ApiInstance {
  core: ApiCore<unknown>
}

export default defineNuxtPlugin((nuxtApp) => {
  const { $auth } = useNuxtApp()
  const userStore = useUserStore()

  const configCore: ApiConfig = {
    format: 'json',
    baseURL: nuxtApp.$config.public.apiBase as string,
  }

  const apiCore = new ApiCore(configCore)

  const modules: ApiInstance = {
    core: apiCore,
  }

  const quickSetToken = async () => {
    Bugsnag.leaveBreadcrumb('Quickset Token called')
    try {
      const userUpdate = await $auth.amplify.currentAuthenticatedUser()

      if (userUpdate.signInUserSession) {
        // skipcq: JS-0357
        updateToken(userUpdate.signInUserSession.idToken?.jwtToken)
        userStore.setUserAmplify(userUpdate)
      }
    } catch (error) {
      Bugsnag.leaveBreadcrumb('QuickSet Token Error refreshing token', {
        error,
      })
      Bugsnag.notify(error)
      userStore.purge()
    }
  }

  const refreshToken = async () => {
    Bugsnag.leaveBreadcrumb('refreshToken called')
    try {
      const userUpdate = await $auth.amplify.currentAuthenticatedUser({
        bypassCache: true,
      })

      // skipcq: JS-0357
      updateToken(userUpdate?.signInUserSession?.idToken?.jwtToken)
      userStore.setUserAmplify(userUpdate)

      const result = await axios.post(
        `${nuxtApp.$config.public.assetBaseUrl}authenticate/token`,
        {
          jwt: userUpdate?.signInUserSession?.idToken?.jwtToken,
        },
        { withCredentials: true },
      )
      if (result.status !== 200) {
        Bugsnag.notify('Failed to refresh assets token')
      }
    } catch (error) {
      Bugsnag.notify(error)
      userStore.ensureLogout()
      throw new Error('Must login')
    }
  }

  const updateToken = (token: string) => {
    modules.core.instance.interceptors.request.clear()
    modules.core.instance.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`
      return config
    })
  }

  modules.core.instance.interceptors.response.use(
    undefined,
    async (error: AxiosError) => {
      const { config } = error
      const status = error.response?.status ?? 500
      switch (status) {
        case 401:
          await refreshToken()
          if (config) {
            const retryRequest = await modules.core.instance.request(config)

            if (retryRequest.status === 401) {
              userStore.ensureLogout()
              return navigateTo('/login')
            } else {
              return retryRequest
            }
          }
          break
        default:
          break
      }
      return Promise.reject(error)
    },
  )

  quickSetToken()

  return {
    name: 'ApiPlugin',
    parallel: true,
    provide: {
      api: modules,
      updateToken,
      refreshToken,
    },
  }
})
