import { default as _401fnxP86FOlDMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/401.vue?macro=true";
import { default as change_45passwordrozeD39yndMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/account/change-password.vue?macro=true";
import { default as indexnTDlmwKCIQMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/account/index.vue?macro=true";
import { default as notificationsozz3omyq5xMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/account/notifications.vue?macro=true";
import { default as preferences9khdzfbnMJMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/account/preferences.vue?macro=true";
import { default as accountvMbtXyMDOXMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/account.vue?macro=true";
import { default as _91invoiceid_93fo7s4FKj5eMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/accounting/invoice/[invoiceid].vue?macro=true";
import { default as _91postageid_93ae8zlDvjWKMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/accounting/invoice/[serviceid]/[postageid].vue?macro=true";
import { default as indexhTaary8YidMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/accounting/invoice/index.vue?macro=true";
import { default as newinvoice5bua9d7QSpMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/accounting/invoice/newinvoice.vue?macro=true";
import { default as companyZKUbYb4hcPMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/company.vue?macro=true";
import { default as indexY5rB4QS6c4Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/confirm/email/[email]/index.vue?macro=true";
import { default as indexLaDbXkRjuUMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/confirm/index.vue?macro=true";
import { default as indexv9ZxpDgzv5Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/reset-password/email/[email]/code/[code]/index.vue?macro=true";
import { default as indexWknMG2fyJMMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/reset-password/email/[email]/index.vue?macro=true";
import { default as indexVlgVHUY4IEMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/reset-password/index.vue?macro=true";
import { default as campaignsKbWVxOB7FXMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/campaigns.vue?macro=true";
import { default as campaignSupportKih7yHtxIjMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/campaignSupport.vue?macro=true";
import { default as _91folderName_935Emq09ckg6Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/assets/[folderName].vue?macro=true";
import { default as indexB8EYfL2ZSbMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/assets/index.vue?macro=true";
import { default as billingvfsF07HosbMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/billing.vue?macro=true";
import { default as index4UjS7E360NMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaigns/[campaignId]/index.vue?macro=true";
import { default as createxUJAAtNDl6Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaigns/create.vue?macro=true";
import { default as index0Jp6SwtAoZMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaigns/index.vue?macro=true";
import { default as campaignSupportDUiOFKqfIWMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaignSupport.vue?macro=true";
import { default as campaignSupports2RY7ygWg5OMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaignSupports.vue?macro=true";
import { default as creditscP3nZN2EBiMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/credits.vue?macro=true";
import { default as discountsRdDS5rVW26Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/discounts.vue?macro=true";
import { default as historyIWUY783WrXMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/history.vue?macro=true";
import { default as indexNBKCIKbUOlMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/index.vue?macro=true";
import { default as index7Qr9n68vsCMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/file/[listFileId]/index.vue?macro=true";
import { default as resultsueHEAi2jcvMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/file/[listFileId]/results.vue?macro=true";
import { default as index8QrfIe8nypMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/index.vue?macro=true";
import { default as upload1an5GfSpItMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/upload.vue?macro=true";
import { default as createznPAxF8JQ6Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/create.vue?macro=true";
import { default as index59P2NL4Tr0Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/index.vue?macro=true";
import { default as indexX1ltMRQcdHMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/locations/[locationId]/index.vue?macro=true";
import { default as indexOx32TfYHKgMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/locations/index.vue?macro=true";
import { default as locationsdX62eiPJe7Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/locations.vue?macro=true";
import { default as settingsdRo2NC7kNsMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/settings.vue?macro=true";
import { default as indexXZXmnhuw4BMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/users/index.vue?macro=true";
import { default as users6lNBzLfcs7Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/users.vue?macro=true";
import { default as createp82qzStFCrMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/create.vue?macro=true";
import { default as indexBfE1GwOSc0Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/index.vue?macro=true";
import { default as companyfPzteudkO0Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company.vue?macro=true";
import { default as faqcn7zQLbSYPMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/faq.vue?macro=true";
import { default as forgotPassword9jeN0Pkm0OMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/forgotPassword.vue?macro=true";
import { default as index0Hquz1dJouMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/index.vue?macro=true";
import { default as listsuN0P9iGNEfMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/lists.vue?macro=true";
import { default as loginfvw1wfu1dxMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/login.vue?macro=true";
import { default as privacy9gE8S3bhm9Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/privacy.vue?macro=true";
import { default as indexUDO8h0nBf1Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/[productid]/index.vue?macro=true";
import { default as categoriesrqkauglh6AMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/categories.vue?macro=true";
import { default as envelopesNuY0Qxw1ZtMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/envelopes.vue?macro=true";
import { default as indexaiWibLfWB6Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/index.vue?macro=true";
import { default as letterShop5hJthmNit9Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/letterShop.vue?macro=true";
import { default as paperStockfgj7FqE54nMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/paperStock.vue?macro=true";
import { default as productHistory2DRMkOhZkfMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/productHistory.vue?macro=true";
import { default as registerdJCzS6FEmqMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/register.vue?macro=true";
import { default as indexw15Li3rtW0Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/reports/index.vue?macro=true";
import { default as brandsqMCGf8xgM7Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/brands.vue?macro=true";
import { default as index7HR0D3RDLJMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/campaignType/[campaignTypeId]/index.vue?macro=true";
import { default as indexSXrLcE2nsDMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/campaignType/index.vue?macro=true";
import { default as creditszDjQwKC2xLMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/credits.vue?macro=true";
import { default as discountsJPI9q6xqgeMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/discounts.vue?macro=true";
import { default as indexfqklpF4pmvMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/index.vue?macro=true";
import { default as jobIntegrationsFFZQxfnRnoMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/jobIntegrations.vue?macro=true";
import { default as supportnkX95JW7KVMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/support.vue?macro=true";
import { default as terms8p0pIoEthMMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/terms.vue?macro=true";
import { default as clientWLVRjtXMVnMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/users/client.vue?macro=true";
import { default as indexnFwzREju40Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/users/index.vue?macro=true";
import { default as internalJhCltyAbwhMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/users/internal.vue?macro=true";
import { default as usersSFwlHZ9KFeMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/users.vue?macro=true";
import { default as version2DVDRheQ4yMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/version.vue?macro=true";
export default [
  {
    name: "401",
    path: "/401",
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/401.vue")
  },
  {
    name: accountvMbtXyMDOXMeta?.name,
    path: "/account",
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/account.vue"),
    children: [
  {
    name: "account-change-password",
    path: "change-password",
    meta: change_45passwordrozeD39yndMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/account/change-password.vue")
  },
  {
    name: "account",
    path: "",
    meta: indexnTDlmwKCIQMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/account/index.vue")
  },
  {
    name: "account-notifications",
    path: "notifications",
    meta: notificationsozz3omyq5xMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/account/notifications.vue")
  },
  {
    name: "account-preferences",
    path: "preferences",
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/account/preferences.vue")
  }
]
  },
  {
    name: "accounting-invoice-invoiceid",
    path: "/accounting/invoice/:invoiceid()",
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/accounting/invoice/[invoiceid].vue")
  },
  {
    name: "accounting-invoice-serviceid-postageid",
    path: "/accounting/invoice/:serviceid()/:postageid()",
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/accounting/invoice/[serviceid]/[postageid].vue")
  },
  {
    name: "accounting-invoice",
    path: "/accounting/invoice",
    meta: indexhTaary8YidMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/accounting/invoice/index.vue")
  },
  {
    name: "accounting-invoice-newinvoice",
    path: "/accounting/invoice/newinvoice",
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/accounting/invoice/newinvoice.vue")
  },
  {
    name: "auth-company",
    path: "/auth/company",
    meta: companyZKUbYb4hcPMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/company.vue")
  },
  {
    name: "auth-confirm-email-email",
    path: "/auth/confirm/email/:email()",
    meta: indexY5rB4QS6c4Meta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/confirm/email/[email]/index.vue")
  },
  {
    name: "auth-confirm",
    path: "/auth/confirm",
    meta: indexLaDbXkRjuUMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/confirm/index.vue")
  },
  {
    name: "auth-reset-password-email-email-code-code",
    path: "/auth/reset-password/email/:email()/code/:code()",
    meta: indexv9ZxpDgzv5Meta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/reset-password/email/[email]/code/[code]/index.vue")
  },
  {
    name: "auth-reset-password-email-email",
    path: "/auth/reset-password/email/:email()",
    meta: indexWknMG2fyJMMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/reset-password/email/[email]/index.vue")
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    meta: indexVlgVHUY4IEMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/reset-password/index.vue")
  },
  {
    name: "campaigns",
    path: "/campaigns",
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/campaigns.vue")
  },
  {
    name: "campaignSupport",
    path: "/campaignSupport",
    meta: campaignSupportKih7yHtxIjMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/campaignSupport.vue")
  },
  {
    name: companyfPzteudkO0Meta?.name,
    path: "/company",
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company.vue"),
    children: [
  {
    name: "company-companyId-assets-folderName",
    path: ":companyId()/assets/:folderName()",
    meta: _91folderName_935Emq09ckg6Meta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/assets/[folderName].vue")
  },
  {
    name: "company-companyId-assets",
    path: ":companyId()/assets",
    meta: indexB8EYfL2ZSbMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/assets/index.vue")
  },
  {
    name: "company-companyId-billing",
    path: ":companyId()/billing",
    meta: billingvfsF07HosbMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/billing.vue")
  },
  {
    name: "company-companyId-campaigns-campaignId",
    path: ":companyId()/campaigns/:campaignId()",
    meta: index4UjS7E360NMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaigns/[campaignId]/index.vue")
  },
  {
    name: "company-companyId-campaigns-create",
    path: ":companyId()/campaigns/create",
    meta: createxUJAAtNDl6Meta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaigns/create.vue")
  },
  {
    name: "company-companyId-campaigns",
    path: ":companyId()/campaigns",
    meta: index0Jp6SwtAoZMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaigns/index.vue")
  },
  {
    name: "company-companyId-campaignSupport",
    path: ":companyId()/campaignSupport",
    meta: campaignSupportDUiOFKqfIWMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaignSupport.vue")
  },
  {
    name: "company-companyId-campaignSupports",
    path: ":companyId()/campaignSupports",
    meta: campaignSupports2RY7ygWg5OMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaignSupports.vue")
  },
  {
    name: "company-companyId-credits",
    path: ":companyId()/credits",
    meta: creditscP3nZN2EBiMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/credits.vue")
  },
  {
    name: "company-companyId-discounts",
    path: ":companyId()/discounts",
    meta: discountsRdDS5rVW26Meta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/discounts.vue")
  },
  {
    name: "company-companyId-history",
    path: ":companyId()/history",
    meta: historyIWUY783WrXMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/history.vue")
  },
  {
    name: "company-companyId",
    path: ":companyId()",
    meta: indexNBKCIKbUOlMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/index.vue")
  },
  {
    name: "company-companyId-lists-listId-file-listFileId",
    path: ":companyId()/lists/:listId()/file/:listFileId()",
    meta: index7Qr9n68vsCMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/file/[listFileId]/index.vue")
  },
  {
    name: "company-companyId-lists-listId-file-listFileId-results",
    path: ":companyId()/lists/:listId()/file/:listFileId()/results",
    meta: resultsueHEAi2jcvMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/file/[listFileId]/results.vue")
  },
  {
    name: "company-companyId-lists-listId",
    path: ":companyId()/lists/:listId()",
    meta: index8QrfIe8nypMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/index.vue")
  },
  {
    name: "company-companyId-lists-listId-upload",
    path: ":companyId()/lists/:listId()/upload",
    meta: upload1an5GfSpItMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/upload.vue")
  },
  {
    name: "company-companyId-lists-create",
    path: ":companyId()/lists/create",
    meta: createznPAxF8JQ6Meta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/create.vue")
  },
  {
    name: "company-companyId-lists",
    path: ":companyId()/lists",
    meta: index59P2NL4Tr0Meta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/index.vue")
  },
  {
    name: locationsdX62eiPJe7Meta?.name,
    path: ":companyId()/locations",
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/locations.vue"),
    children: [
  {
    name: "company-companyId-locations-locationId",
    path: ":locationId()",
    meta: indexX1ltMRQcdHMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/locations/[locationId]/index.vue")
  },
  {
    name: "company-companyId-locations",
    path: "",
    meta: indexOx32TfYHKgMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/locations/index.vue")
  }
]
  },
  {
    name: "company-companyId-settings",
    path: ":companyId()/settings",
    meta: settingsdRo2NC7kNsMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/settings.vue")
  },
  {
    name: users6lNBzLfcs7Meta?.name,
    path: ":companyId()/users",
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/users.vue"),
    children: [
  {
    name: "company-companyId-users",
    path: "",
    meta: indexXZXmnhuw4BMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/users/index.vue")
  }
]
  },
  {
    name: "company-create",
    path: "create",
    meta: createp82qzStFCrMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/create.vue")
  },
  {
    name: "company",
    path: "",
    meta: indexBfE1GwOSc0Meta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/index.vue")
  }
]
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqcn7zQLbSYPMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/faq.vue")
  },
  {
    name: "forgotPassword",
    path: "/forgotPassword",
    meta: forgotPassword9jeN0Pkm0OMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/forgotPassword.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index0Hquz1dJouMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/index.vue")
  },
  {
    name: "lists",
    path: "/lists",
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/lists.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginfvw1wfu1dxMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/login.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacy9gE8S3bhm9Meta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/privacy.vue")
  },
  {
    name: "products-productid",
    path: "/products/:productid()",
    meta: indexUDO8h0nBf1Meta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/[productid]/index.vue")
  },
  {
    name: "products-categories",
    path: "/products/categories",
    meta: categoriesrqkauglh6AMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/categories.vue")
  },
  {
    name: "products-envelopes",
    path: "/products/envelopes",
    meta: envelopesNuY0Qxw1ZtMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/envelopes.vue")
  },
  {
    name: "products",
    path: "/products",
    meta: indexaiWibLfWB6Meta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/index.vue")
  },
  {
    name: "products-letterShop",
    path: "/products/letterShop",
    meta: letterShop5hJthmNit9Meta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/letterShop.vue")
  },
  {
    name: "products-paperStock",
    path: "/products/paperStock",
    meta: paperStockfgj7FqE54nMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/paperStock.vue")
  },
  {
    name: "products-productHistory",
    path: "/products/productHistory",
    meta: productHistory2DRMkOhZkfMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/productHistory.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerdJCzS6FEmqMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/register.vue")
  },
  {
    name: "reports",
    path: "/reports",
    meta: indexw15Li3rtW0Meta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/reports/index.vue")
  },
  {
    name: "settings-brands",
    path: "/settings/brands",
    meta: brandsqMCGf8xgM7Meta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/brands.vue")
  },
  {
    name: "settings-campaignType-campaignTypeId",
    path: "/settings/campaignType/:campaignTypeId()",
    meta: index7HR0D3RDLJMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/campaignType/[campaignTypeId]/index.vue")
  },
  {
    name: "settings-campaignType",
    path: "/settings/campaignType",
    meta: indexSXrLcE2nsDMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/campaignType/index.vue")
  },
  {
    name: "settings-credits",
    path: "/settings/credits",
    meta: creditszDjQwKC2xLMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/credits.vue")
  },
  {
    name: "settings-discounts",
    path: "/settings/discounts",
    meta: discountsJPI9q6xqgeMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/discounts.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: indexfqklpF4pmvMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/index.vue")
  },
  {
    name: "settings-jobIntegrations",
    path: "/settings/jobIntegrations",
    meta: jobIntegrationsFFZQxfnRnoMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/jobIntegrations.vue")
  },
  {
    name: "support",
    path: "/support",
    meta: supportnkX95JW7KVMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/support.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: terms8p0pIoEthMMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/terms.vue")
  },
  {
    name: usersSFwlHZ9KFeMeta?.name,
    path: "/users",
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/users.vue"),
    children: [
  {
    name: "users-client",
    path: "client",
    meta: clientWLVRjtXMVnMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/users/client.vue")
  },
  {
    name: "users",
    path: "",
    meta: indexnFwzREju40Meta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/users/index.vue")
  },
  {
    name: "users-internal",
    path: "internal",
    meta: internalJhCltyAbwhMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/users/internal.vue")
  }
]
  },
  {
    name: "version",
    path: "/version",
    meta: version2DVDRheQ4yMeta || {},
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/version.vue")
  }
]